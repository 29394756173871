import { createSlice } from "@reduxjs/toolkit";

const initialState = 
  {
    // matchId: 0,
    batsmanFacing: "",
    bowler: "",
    overBall: "",
    inningsId:"",
    matchId:"",
    batsman1OnCreeze:"",
    batsman1OnCreezeName:"",
    batsman2OnCreeze:"",
    batsman2OnCreezeName:""
  }

// const initialState = [
//   {
//     // matchId: 0,
//     batsmanFacing: "",
//     bowler: "",
//     overBall: "",
//   },
// ];

export const DeliverySlice = createSlice({
  name: "delivery",
  initialState,
  reducers: {
    batsmanData: (state, action) => {
      // ...
      // matchid == 
      // ...
      // state.batsmanFacing = 
      state.batsmanFacing = action?.payload?.data;
      state.inningsId = action?.payload?.innings;
      state.matchId = action?.payload?.matchId;
    },
    bowlerData: (state, action) => {
      state.bowler = action?.payload?.data
      state.inningsId = action?.payload?.innings;
      state.matchId = action?.payload?.matchId;
    },
    overBallData: (state, action) => {
      state.overBall = action?.payload?.data
      state.inningsId = action?.payload?.innings;
      state.matchId = action?.payload?.matchId;
    },
    batsman1OnCreeze: (state, action) => {
      state.batsman1OnCreeze = action?.payload?.data;
      // state.batsman1OnCreezeName = action?.payload?.data;
      state.inningsId = action?.payload?.innings;
      state.matchId = action?.payload?.matchId;
    },
    batsman2OnCreeze: (state, action) => {
      state.batsman2OnCreeze = action?.payload?.data;
      // state.batsman2OnCreezeName = action?.payload?.data;
      state.inningsId = action?.payload?.innings;
      state.matchId = action?.payload?.matchId;
    },
  },
});

export const { batsmanData, bowlerData, overBallData, batsman1OnCreeze, batsman2OnCreeze } = DeliverySlice.actions;

export default DeliverySlice.reducer;
