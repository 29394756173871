import { toast } from 'react-toastify';


const ToastComp = ({varient,message}) => {
   switch (varient) {
    case "success":
        toast.success(message,{
            position: toast.POSITION.BOTTOM_RIGHT,
            className: 'foo-bar',
            autoClose: 2000,
            pauseOnHover: false,
          })
        break;
    case "error":
        toast.error(message,{
            position: toast.POSITION.BOTTOM_RIGHT,
            className: 'foo-bar',
            autoClose: 15000,
            pauseOnHover: true,
          })
    
        break;
    case "warn":
        toast.warn(message,{
            position: toast.POSITION.BOTTOM_RIGHT,
            className: 'foo-bar',
            autoClose: 2000,
            pauseOnHover: false,
          })

        break;
    case "info":
        toast.info(message,{
            position: toast.POSITION.BOTTOM_RIGHT,
            className: 'foo-bar',
            autoClose: 2000,
            pauseOnHover: false,
          })

        break;
   
    default:
        break;
   } 


}

export default ToastComp

