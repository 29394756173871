import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
  contestGroupIds: [],
  dateFrom: moment(new Date()).format("YYYY-MM-DD"),
  dateTo: moment(new Date()).format("YYYY-MM-DD"),
  provider: "",
  statuses: [],
  contestOptions: [],
  sortOrder: "",
  startInterval: false,
};


export const MatchesFilterSlice = createSlice({
  name: "matchFilterSlice",
  initialState,
  reducers: {
    matchFilters: (state, action) => {
        // console.log("--- action from slice ---: ", action.payload)
      switch (action.payload.type) {
        case "contestChange":
          return { ...state, contestGroupIds: action.payload.data };
        case "fromDateChange":
          return { ...state, dateFrom: action.payload.data };
        case "toDateChange":
          return { ...state, dateTo: action.payload.data };
        case "providerChange":
          return { ...state, provider: action.payload.data };
        case "statusChange":
          return { ...state, statuses: action.payload.data };
        case "setcontestoptions":
          return { ...state, contestOptions: action.payload.data };
        case "sortchange":
          return { ...state, sortOrder: action.payload.data };
        case "startInterval":
          return { ...state, startInterval: action.payload.data };
        case "clear":
          return {
            contestGroupIds: [],
            dateFrom: moment(new Date()).format("YYYY-MM-DD"),
            dateTo: moment(new Date()).format("YYYY-MM-DD"),
            provider: "",
            statuses: [],
            contestOptions: [],
            sortOrder: "",
          };
        default:
          return state;
      }
    },
  },
});

export const { matchFilters } = MatchesFilterSlice.actions;

export default MatchesFilterSlice.reducer;
