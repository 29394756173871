import SideB from "components/sideBar/Sidebar";
import React, { useState } from "react";
import Navbar from "../components/navBar/nav";
import { Outlet } from "react-router-dom";

const Layout = () => {
  const [checkToggle, setCheckToggle] = useState(false);
  return (
    <div
      className={
        checkToggle ? `sb-nav-fixed sb-sidenav-toggled` : `sb-nav-fixed`
      }
    >
      <Navbar checkToggle={checkToggle} setCheckToggle={setCheckToggle} />
      <div id="layoutSidenav">
        <SideB />
        <div id="layoutSidenav_content">
          <main className="main-content-cus">
            <div className="card-cus">
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

  export default Layout