/* eslint-disable no-unused-vars */
import React from "react";
import "./sidebar.css";
import Dropdown from "./Dropdown";
import {
  cricketData,
  footbalData,
  systemData,
  tennisData,
  basketballData,
  hockeyData,
  handballData,
  feildHockeyData,
  volleyballData,
  SnookerData,
  americanFootballData,
  floorBall,
  rugbyData,
  golfData
} from "./helper";
import { ReactComponent as CricIcon } from "assets/svg/cricketIcon.svg";
import { ReactComponent as TennisBall } from "assets/svg/tennisBall.svg";
import { ReactComponent as SystemSvg } from "assets/svg/system.svg";
import { ReactComponent as FootballSvg } from "assets/svg/football.svg";
import { ReactComponent as Floorball } from "assets/svg/Floorball.svg";
import { ReactComponent as BasketballSvg } from "assets/svg/basketball.svg";
import { ReactComponent as IceHockey } from "assets/svg/IceHockey.svg";
import { ReactComponent as FeildHockey } from "assets/svg/feildHockey.svg";
import { ReactComponent as Handball } from "assets/svg/handball.svg";
import { ReactComponent as Volleyball } from "assets/svg/volleyball.svg";
import { ReactComponent as Snooker } from "assets/svg/snooker.svg";
import { ReactComponent as RugbyIcon } from "assets/svg/rugby.svg";
import { ReactComponent as AmericanFootball } from "assets/svg/americanFootball.svg";
import { ReactComponent as Golf } from "assets/svg/golf.svg";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// import img1 from "../../assets/imgs/img1.png";

// import {
//   faEye,
//   faPencil,
//   faTrash,
//   faCircle,
//   faTableTennis,
//   amazon,
// } from "@fortawesome/free-solid-svg-icons";

<i class="fa-solid fa-cricket-bat-ball"></i>;

function SideB() {
  const loc = useLocation();
  const ops = window.location.pathname.split("/");
  const [currentMenu, setCurrentMenu] = useState(
    ops.length > 0 ? (ops[1] ? ops[1] : "") : ""
  );
  useEffect(() => {
    setCurrentMenu(ops.length > 0 ? (ops[1] ? ops[1] : "") : "");
  }, [loc.pathname]);

  return (
    <div id="layoutSidenav">
      <div id="layoutSidenav_nav">
        <nav
          className="sb-sidenav accordion sb-sidenav-dark"
          id="sidenavAccordion"
        >
          <div className="sb-sidenav-menu">
            {/* <Dropdown
              title="Cricket"
              list={cricketData}
              status={currentMenu === "cricket" ? true : false}
              currentMenu={currentMenu}
              setCurrentMenu={setCurrentMenu}
              name="cricket"
            >
              <CricIcon className="me-1" />
            </Dropdown> */}

            <Dropdown
              title="Football"
              list={footbalData}
              status={currentMenu === "football" ? true : false}
              currentMenu={currentMenu}
              setCurrentMenu={setCurrentMenu}
              name="football"
            >
              <FootballSvg className="me-1" />
            </Dropdown>

            <Dropdown
              title="Tennis"
              list={tennisData}
              status={false}
              currentMenu={currentMenu}
              setCurrentMenu={setCurrentMenu}
              name="tennis"
            >
              <TennisBall className="me-1" />
            </Dropdown>

            <Dropdown
              title="Basketball"
              list={basketballData}
              status={false}
              currentMenu={currentMenu}
              setCurrentMenu={setCurrentMenu}
              name="basketball"
            >
              <BasketballSvg className="me-1" />
            </Dropdown>

            <Dropdown
              title="Ice Hockey"
              list={hockeyData}
              status={false}
              currentMenu={currentMenu}
              setCurrentMenu={setCurrentMenu}
              name="hockey"
            >
              <IceHockey className="me-1" />
            </Dropdown>

            <Dropdown
              title="Handball"
              list={handballData}
              status={false}
              currentMenu={currentMenu}
              setCurrentMenu={setCurrentMenu}
              name="handball"
            >
              <Handball className="me-1" />
            </Dropdown>

            {/* <Dropdown
              title="Field Hockey"
              list={feildHockeyData}
              status={false}
              currentMenu={currentMenu}
              setCurrentMenu={setCurrentMenu}
              name="field-hockey"
            >
              <FeildHockey className="me-1" />
            </Dropdown> */}

            <Dropdown
              title="Volleyball"
              list={volleyballData}
              status={false}
              currentMenu={currentMenu}
              setCurrentMenu={setCurrentMenu}
              name="volleyball"
            >
              <Volleyball className="me-1" />
            </Dropdown>

            {/* <Dropdown
              title="Snooker"
              list={SnookerData}
              status={currentMenu === "snooker" ? true : false}
              currentMenu={currentMenu}
              setCurrentMenu={setCurrentMenu}
              name="snooker"
            >
              <Snooker className="me-1 snooker_svg" />
            </Dropdown> */}

            <Dropdown
              title="American Football"
              list={americanFootballData}
              status={false}
              currentMenu={currentMenu}
              setCurrentMenu={setCurrentMenu}
              name="american-football"
            >
              <AmericanFootball className="me-1" />
            </Dropdown>

            {/* <Dropdown
              title="Floorball"
              list={floorBall}
              status={currentMenu === "floorball" ? true : false}
              currentMenu={currentMenu}
              setCurrentMenu={setCurrentMenu}
              name="floorball"
            >
              <Floorball className="me-1" />
            </Dropdown> */}

            <Dropdown
              title="Rugby"
              list={rugbyData}
              status={currentMenu === "rugby" ? true : false}
              currentMenu={currentMenu}
              setCurrentMenu={setCurrentMenu}
              name="rugby"
            >
              <RugbyIcon className="me-1" />
            </Dropdown>

            {/* <Dropdown
              title="Golf"
              list={golfData}
              status={currentMenu === "golf" ? true : false}
              currentMenu={currentMenu}
              setCurrentMenu={setCurrentMenu}
              name="golf"
            >
              <Golf className="me-1" />
            </Dropdown> */}

            <Dropdown
              title="System Data"
              list={systemData}
              status={false}
              currentMenu={currentMenu}
              setCurrentMenu={setCurrentMenu}
              name="systemdata"
            >
              <SystemSvg className="me-1" />
            </Dropdown>

            {/* <Dropdown title="System Data" list={systemData} status={false}  /> */}
          </div>
        </nav>
      </div>
    </div>
  );
}
export default SideB;
