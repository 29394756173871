import { createSlice } from "@reduxjs/toolkit";

const initialState = 
  {
    matchData:{},
  }

// const initialState = [
//   {
//     matchData:{},
//   },
// ];

export const MatchSlice = createSlice({
  name: "delivery",
  initialState,
  reducers: {
    match: (state, action) => {
      // console.log("action.payload", action.payload)
      state.matchData = action.payload;
    }
  },
});

export const { match } = MatchSlice.actions;

export default MatchSlice.reducer;
