import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import DeliverySlice from './store/features/delivery/DeliverySlice'
import MatchesFilterSlice  from './store/features/football/MatchesFilterSlice'
import MatchSlice from './store/features/match/MatchSlice'
import { authReducer } from './store/features/auth/auth.slice'

const persistConfig = {
  key: 'root',
  storage,
}

const rootReducer = combineReducers({ 
  DeliverySlice:DeliverySlice, 
  MatchSlice:MatchSlice,
  MatchesFilterSlice:MatchesFilterSlice,
  auth: authReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export const persistor = persistStore(store)
